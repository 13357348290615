import React, { useEffect, useState } from 'react'

import "../../Assets/Css/Register.css"
import axios from 'axios'
import Loader from '../Register/Loader'

const GenerateReviewLink = () => {

    const [loading, setLoading] = useState(false)
    const [copyText, setCopyText] = useState(false);
    const [reviewLink, setReviewLink] = useState(null)

    // const [popup, setPopUp] = useState(false)

    const [candidate_name, setcandidate_name] = useState('')
    const [industry, setindustry] = useState('')
    const [aadhar_no, setaadhar_no] = useState('')

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])

    document.title = "Generate Review Link | JobFitMeter"

    const handleGenerateLink = async (e) => {
        e.preventDefault();

        try {
            setLoading(true)

            const formData = new FormData();

            formData.append("candidate_name", candidate_name)
            formData.append("industry", industry)
            formData.append("aadhar_no", aadhar_no)
            formData.append("type", 1)

            const response = await axios.post("https://api.jobfitmeter.com/api/generate_link", formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })

            console.log(response)

            setReviewLink(response.data.data.candidate[0].generate_link)

            setLoading(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    return (
        <>
            {
                loading && <Loader />
            }
            <section className="container-box2 register-bg py-5">
                <form action="" onSubmit={handleGenerateLink} className='px-md-5 py-md-5 p-4 my-md-4 rounded'>
                    <div>
                        <h2 className='fw-bold text-dark-blue'>Generate Review Link</h2>
                        <div className="line"></div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Aadhar Number <span className='text-danger'>*</span> </label>
                            <input type="text"
                                value={aadhar_no}
                                onChange={(e) => setaadhar_no(e.target.value)}
                                placeholder='Enter Aadhar Number'
                                className='form-field' required />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Candidate Name <span className='text-danger'>*</span> </label>
                            <input type="text"
                                value={candidate_name}
                                onChange={(e) => setcandidate_name(e.target.value)}
                                placeholder='Enter Candidate Name'
                                className='form-field' required />
                        </div>
                        <div className="col-md-4 mb-md-2 mb-4" >

                            <label htmlFor="">Industry <span className='text-danger'>*</span></label>

                            <select className='form-field' value={industry}
                                onChange={(e) => setindustry(e.target.value)} required >

                                <option value="">--Choose Industry--</option>
                                <option value="Information technology">Information technology</option>
                                <option value="Pharmaceutical industry">Pharmaceutical industry</option>
                                <option value="Petroleum industry">Petroleum industry</option>
                                <option value="Automotive industry">Automotive industry</option>
                                <option value="Textile industry">Textile industry</option>
                                <option value="Manufacturing industry">Manufacturing industry</option>
                            </select>
                        </div>

                        <div className="col-12 text-md-end text-center mt-2" >
                            <button type='reset' className='blue-button bg-secondary me-4' onClick={() => {
                                setReviewLink(null)
                                setindustry('')
                                setcandidate_name('')
                                setaadhar_no('')
                            }}>
                                <i className="bi bi-arrow-clockwise me-2"></i>
                                Reset
                            </button>
                            <button type='submit' className='blue-button '>
                                <i className="bi bi-link-45deg me-2"></i>
                                Generate Link
                            </button>
                        </div>
                        {
                            reviewLink ?
                                <div className="mt-5 pb-2 justify-content-end">
                                    <div className="link-container">
                                        <p className='link w-75 me-2 mb-0'>
                                            {`https://www.jobfitmeter.com/candidate/${reviewLink}`}</p>
                                        <button type='button'
                                            className='link-copy-btn'
                                            value={`https://www.jobfitmeter.com/candidate/${reviewLink}`}
                                            onClick={(e) => {
                                                navigator.clipboard.writeText(e.target.value)
                                                setCopyText(true)
                                                setTimeout(() => {
                                                    setCopyText(false)
                                                }, 1000)
                                            }}
                                        >
                                            {
                                                copyText ? 'Copied!' : 'Copy'
                                            }
                                        </button>
                                    </div>
                                </div> : null
                        }
                    </div>
                </form>
            </section>
        </>
    )
}

export default GenerateReviewLink
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {

    const [stickyNavbar, setStickyNavbar] = useState(false)

    window.addEventListener('scroll', () => {
        if (window.scrollY > 80) {
            setStickyNavbar(true);
        } else {
            setStickyNavbar(false);
        }
    })

    return (
        <>
            <nav className={`container-box2 navibar ${stickyNavbar ? 'fixedNavbar' : ''}`}>
                <div className={`left-nav ${stickyNavbar && 'hide-logo'}`}>
                    <div className='text-center'>
                        <Link to="/" className='logo'>
                            JobFitMeter
                        </Link>
                    </div>
                </div>

                <div className='right-nav'>
                    <a href="https://app.jobfitmeter.com/" className='blue-button bg-white text-decoration-none text-dark me-md-4 me-3'>
                        <i className="bi bi-person me-2"></i>
                        Login
                    </a>
                    <Link to="/register" className='blue-button text-decoration-none'>
                        <i className="bi bi-person me-2"></i>
                        Register Now
                    </Link>
                </div>
            </nav>
        </>
    )
}

export default Header
import React, { useEffect, useState } from 'react'

import "../../Assets/Css/Register.css"
import axios from 'axios'
import Loader from './Loader'
import { allIndustries } from './AllIndustries'

const Register = () => {

    const [company_name, setcompany_name] = useState('')
    const [email, setemail] = useState('')
    const [phone, setphone] = useState('')
    const [website, setwebsite] = useState('')
    const [city, setcity] = useState('')
    const [state, setstate] = useState('')
    const [country, setcountry] = useState('India')
    const [address, setaddress] = useState('')
    const [gst_no, setgst_no] = useState('')
    const [pan_no, setpan_no] = useState('')
    const [industry, setindustry] = useState('')

    const [loading, setLoading] = useState(false)

    const [registerType, setRegisterType] = useState(0)
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [popup, setPopUp] = useState(false)

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });

        const getStates = async () => {
            try {
                const { data } = await axios.get('https://api.jobfitmeter.com/api/state');
                setStates(data.data)
                setcountry(data.data[0].country_id)
            } catch (error) {
                console.log(error)
            }
        }
        getStates()

    }, [])

    document.title = "Register | JobFitMeter"

    const handleRegister = async e => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("company_name", company_name)
        formData.append("industry", industry)
        formData.append("email", email)
        formData.append("phone", phone)
        formData.append("website", website)
        formData.append("city", city)
        formData.append("state", state)
        formData.append("country", country)
        formData.append("address", address)
        formData.append("register", registerType)
        formData.append("gst_no", gst_no)
        formData.append("pan_no", pan_no)

        console.log(registerType)

        try {
            const response = await axios.post('https://api.jobfitmeter.com/api/register', formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })

            console.log(response)

            setPopUp(true)

            setcompany_name('')
            setemail('')
            setphone('')
            setwebsite('')
            setcity('')
            setstate('')
            setaddress('')
            setgst_no('')
            setpan_no('')
            setindustry('')

            setLoading(false)

        } catch (err) {
            console.log(err)
            alert(err.response.data.message)
            setLoading(false)
        }
    }

    const getCities = async (stateId) => {
        try {
            setLoading(true)

            const formData = new FormData()
            formData.append("state_id", stateId)

            const { data } = await axios.post('https://api.jobfitmeter.com/api/city', formData, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "multipart/form-data",
                }
            })
            setCities(data.data)
            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)

        }
    }

    return (
        <>
            {
                loading && <Loader />
            }

            <section className="container-box2 register-bg py-5">
                <form action="" onSubmit={handleRegister} className='px-md-5 py-md-5 p-4 my-md-4 rounded' >
                    <div className='d-flex justify-content-between flex-md-row flex-column'>
                        <div>
                            <h2 className='fw-bold text-dark-blue'>Registration Form</h2>
                            <div className="line"></div>
                        </div>
                        <div className='text-red mb-md-0 mb-3'>
                            <i className="bi bi-info-circle"></i>
                            <span className='ps-2'>Registration process is only open for India</span>
                        </div>
                    </div>

                    <h5 className='fw-bold text-dark-blue'>Company Information </h5>

                    <div className="row mt-4">
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Company Name <span className='text-danger'>*</span> </label>
                            <input type="text" value={company_name} onChange={(e) => setcompany_name(e.target.value)} placeholder='Enter Company Name' className='form-field' required />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Industry <span className='text-danger'>*</span></label>

                            <select value={industry} onChange={(e) => setindustry(e.target.value)} className='form-field' required>
                                <option value="">--Choose Industry--</option>
                                {
                                    allIndustries.map((industry, i) => {
                                        return (
                                            <option key={i} value={industry}>{industry}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Website URL </label>
                            <input type="text" value={website} onChange={(e) => setwebsite(e.target.value)} placeholder='Enter Website URL' className='form-field' />
                        </div>
                        <div className="col-md-4 mb-md-2 mb-4">
                            <label htmlFor="">Email Address <span className='text-danger'>*</span></label>
                            <input type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder='Enter Email Address' className='form-field' required />
                        </div>
                        <div className="col-md-4 mb-md-2 mb-4 position-relative">
                            <label htmlFor="">Phone Number <span className='text-danger'>*</span></label>
                            <input type="text" maxLength={10}
                                value={phone}
                                onChange={(e) => setphone(e.target.value)}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key))
                                        event.preventDefault();
                                }}
                                placeholder='Enter Phone Number' className='form-field ps-5' required />
                            <span className='indiaCode'>+91</span>
                            <span className='text-danger' style={{ fontSize: '14px' }}>Valid phone number required for verification.</span>
                        </div>

                        <h5 className='fw-bold text-dark-blue mb-4 mt-4'>Location</h5>


                        <div className="col-md-4 mb-md-2 mb-4">
                            <label htmlFor="">Country <span className='text-danger'>*</span></label>
                            <input type="text" disabled value={'India'} placeholder='Enter Country' className='form-field' required />
                        </div>

                        <div className="col-md-4 mb-4">
                            <label htmlFor="">State <span className='text-danger'>*</span></label>
                            <select value={state} onChange={(e) => setstate(e.target.value)} className='form-field' required>
                                <option value="" onClick={() => setCities([])}>--Select State--</option>

                                {
                                    states?.map((state) => {
                                        return (
                                            <option key={state.id} value={state.id} onClick={() => getCities(state.id)}>{state.name}</option>
                                        )
                                    })
                                }
                                {/* -======== */}
                            </select>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">City <span className='text-danger'>*</span></label>
                            <select value={city} onChange={(e) => setcity(e.target.value)} className='form-field' required>
                                <option value="">--Select State--</option>
                                {
                                    cities?.map((city) => {
                                        return (
                                            <option key={city.id} value={city.id}>{city.name}</option>
                                        )
                                    })
                                }
                                {/* -======== */}
                            </select>
                        </div>
                        <div className="col-md-12 mb-4">
                            <label htmlFor="">Address </label>
                            <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} placeholder='Enter Address' className='form-field' />
                        </div>

                        <h5 className='fw-bold text-dark-blue mb-4 mt-4'>Other Information</h5>

                        <div className="col-md-4 mb-md-2 mb-4">
                            <label htmlFor="">PAN No. <span className='text-danger'>*</span></label>
                            <input type="text" value={pan_no} onChange={(e) => setpan_no(e.target.value)} placeholder='Enter PAN No.' className='form-field' required />
                        </div>

                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Did you get GST registeration? <span className='text-danger'>*</span></label>

                            <div className='form-field bg-white fs-5'>
                                <div className="form-switch form-switch-md "
                                    onChange={(e) => setRegisterType(e.target.checked === true ? 1 : 0)}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input second_view"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>

                        </div>

                        {

                            registerType === 0 ?
                                null :

                                < div className="col-md-4 mb-md-2 mb-4">
                                    <label htmlFor="">GST <span className='text-danger'>*</span></label>
                                    <input type="text" value={gst_no} onChange={(e) => setgst_no(e.target.value)} placeholder='Enter GST' className='form-field' required />
                                </div>

                        }

                        <div className="col-md-12 text-center mt-4 pb-2 justify-content-end">
                            <button type='submit' className='blue-button '>
                                <i className="bi bi-check2-circle me-2"></i>
                                Register
                            </button>
                        </div>
                    </div>
                </form>
            </section >

            <div className={`popup-box ${popup ? '' : 'd-none'}`}>
                <div className='popup'>
                    <div className="popup-content">
                        <i className="bi bi-check-circle-fill"></i>
                        <h2 className='mt-2'>Thank You</h2>
                        <p>We have received your message and would like to thank you for writing to us.</p>

                        <button className='border-0' onClick={() => setPopUp(false)}>Ok</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register
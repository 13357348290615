import './App.css';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Home Page';
import Register from './Components/Register/Register';
import GenerateReviewLink from './Components/Home Page/GenerateReviewLink';
import Header from './Components/Home Page/Header';
import PrivacyPolicy from './Components/Policy and Terms/PrivacyPolicy';
import Footer from './Components/Home Page/Footer';
import TermsAndConditions from './Components/Policy and Terms/TermsAndConditions';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />

        <Routes >
          <Route exact path="/" element={<Home />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/generate-review-link" element={<GenerateReviewLink />} />

          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>

        <Footer />

      </BrowserRouter>
    </>
  );
}

export default App;

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])

    return (
        <>
            <section className="page-header py-5">
                <div className="container-box py-4">
                    <div className="text-white text-center">
                        <h1 className="fw-bold display-4">Privacy Policy</h1>
                        <p className="mt-4 header-path-link">
                            <Link to="/" className="text-blue text-decoration-none">
                                {" "}
                                Home
                            </Link>
                            <i className="bi bi-arrow-right mx-3"></i>
                            Privacy Policy
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-box py-md-4">
                    <p className="paragraph fs-5">
                        Welcome to JobFitMeter, the online platform for employers to share candid reviews about candidates and employees. At JobFitMeter, we are committed to protecting your privacy and ensuring a transparent, secure, and informed environment for all users. This Privacy Policy outlines how we collect, use, and protect the information you provide on our platform.
                    </p>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Review and Decision-Making Platform</h2>
                        <p className="paragraph fs-5">
                            JobFitMeter serves as an online platform where employers can post reviews about candidates and employees. These reviews are accessible to other companies, assisting them in making informed decisions before recruiting. Our platform encourages transparency, fairness, and the exchange of constructive feedback.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Disclaimer: Responsibility for Reviews</h2>
                        <p className="paragraph fs-5">
                            JobFitMeter does not assume responsibility for the reviews or ratings provided by registered companies on the JobFitMeter portal. These reviews represent the individual opinions and experiences of the respective employers. We do not endorse, control, or verify the content of these reviews. We encourage employers to provide fair, accurate, and constructive feedback while respecting the privacy and rights of individuals.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Information Collection</h2>

                        <h5 className='fw-bold text-dark-blue'>Candidate Information: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter maintains a minimal database with the sole purpose of ensuring the uniqueness of candidates. We collect only the candidate's name and Aadhar card number to facilitate accurate identification and maintain the integrity of the platform. Candidates do not have the ability to update or edit their information, as this data is solely used for identification and does not include any personal or professional details beyond what's explicitly mentioned.
                        </p>

                        <h5 className='fw-bold text-dark-blue'>Company Registration: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter exclusively focuses on company registration. When a company registers on JobFitMeter, we collect certain company-related information, including company name, email address , GST , PAN and other basic details, to establish the company's identity on the platform. This information is essential for platform access and company identification.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Information Use</h2>
                        <p className="paragraph fs-5 d-flex ">
                            <i class="bi bi-check-lg text-blue pe-3 fs-3"></i>
                            We use the information collected to provide, maintain, and improve our platform.
                        </p>
                        <p className="paragraph fs-5 d-flex">
                            <i class="bi bi-check-lg text-blue pe-3 fs-3"></i>
                            Reviews and information shared by employers and candidates are used to facilitate informed decision-making in the recruitment process.
                        </p>
                        <p className="paragraph fs-5 d-flex">
                            <i class="bi bi-check-lg text-blue pe-3 fs-3"></i>
                            User registration information helps us verify user identities and provide necessary platform features.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Data Security</h2>
                        <p className="paragraph fs-5">
                            We take data security seriously and implement industry-standard measures to safeguard your information. However, please be cautious and use responsible practices when sharing personal information on any online platform.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Cookies and Analytics</h2>
                        <p className="paragraph fs-5">
                            We may use cookies and similar technologies to collect data for analytics, to understand how our platform is used, and to improve user experience. You can manage your cookie preferences in your browser settings.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Data Retention</h2>
                        <p className="paragraph fs-5">
                            We retain user information only for as long as necessary to fulfill the purposes for which it was collected.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Updates to this Policy</h2>
                        <p className="paragraph fs-5">
                            We may update this Privacy Policy to reflect changes in our practices or in compliance with applicable laws. It is advisable to review this policy periodically for any updates.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Contact Us</h2>
                        <p className="paragraph fs-5">
                            If you have any questions or concerns regarding this Privacy Policy or our practices, please contact us at
                            <a href='mailto:support@jobfitmeter.com' className='text-decoration-none text-blue fs-5'> support@jobfitmeter.com</a>
                        </p>

                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Conclusion</h2>
                        <p className="paragraph fs-5">
                            JobFitMeter is committed to upholding user privacy and promoting transparency in the hiring process. By using our platform, you agree to these terms and policies, and you acknowledge the limited role of JobFitMeter in review and information sharing.
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PrivacyPolicy
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className=' pt-5'>
            <div className='container-box2'>
                <div className="row justify-content-center">
                    <div className="col-md-8 px-md-0 text-center footer-content">
                        <h2 className='text-blue'>Join Us in the Pursuit of Hiring Excellence</h2>

                        <p className='paragraph text-white mt-4'>
                            We invite all forward-thinking companies to join us on this journey towards more effective and informed hiring practices. Job Fitmeter is more than a platform; it's a community that shares a vision of building stronger, more successful organizations through the collective wisdom of the business world.
                        </p>


                    </div>
                    <div className="col-12 text-center text-light mt-4">
                        <strong className='fs-5'>
                            Let's work together to shape the future of hiring. Join Job Fitmeter today and discover the power of informed decisions.
                        </strong>

                        <div className='social-icons mt-5'>
                            <i className="bi bi-facebook"></i>
                            <i className="bi bi-twitter"></i>
                            <i className="bi bi-instagram"></i>
                            <i className="bi bi-linkedin"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bottom-footer container-box2 py-4 mt-5 d-flex flex-md-row flex-column 
                            justify-content-between text-white paragraph text-md-start text-center'>
                <div>
                    <span className='pb-md-0 pb-2'> Copyright &copy; 2023 JobFitMeter </span> 
                    <span className='px-2'>|</span>
                    <Link to="/privacy-policy" className='text-decoration-none text-blue'> Privacy Policy </Link>
                    <span className='px-2'>|</span>
                    <Link to="/terms-and-conditions" className='text-decoration-none text-blue'> Terms and Conditions </Link>
                </div>
                <a href='https://www.thestaffguru.com/' target='_blank' rel="noreferrer" className='text-white text-decoration-none'>A Product of Staffguru</a>
            </div>
        </footer>
    )
}

export default Footer
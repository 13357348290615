import React, { useEffect, useState } from 'react'

import "../../Assets/Css/Home.css"

import feature1 from "../../Assets/Images/feature1.png"
import feature2 from "../../Assets/Images/feature2.png"
import feature3 from "../../Assets/Images/feature3.png"
import feature4 from "../../Assets/Images/feature4.png"
import { Link } from 'react-router-dom'
import axios from 'axios'
import Loader from '../Register/Loader'

const Home = () => {

    const [loading, setLoading] = useState(false)
    const [candidateCount, setCandidateCount] = useState(10000)

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });

        async function getCandidateCount() {
            try {
                setLoading(true)
                const response = await axios.get('https://api.jobfitmeter.com/api/view_company', {
                    header: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                });
                setCandidateCount(response.data.data.total_candidate_count + 10000)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        getCandidateCount()
    }, [])


    document.title = "Home | JobFitMeter"

    return (
        <>

            {
                loading && <Loader />
            }

            <section className='container-box2 banner-section'>
                <div className="row align-items-center h-100">
                    <div className="col-md-6 banner-content">
                        <p className=''>We have <span>{candidateCount.toLocaleString()}</span> reviews of candidates!</p>
                        <div className="line"></div>

                        <h1 className='mt-2 mb-5'>
                            Navigating Success with <span className='text-blue'> Job Fitmeter </span> Reviews
                        </h1>

                        <Link to="/register" className='blue-button text-decoration-none'>
                            <i className="bi bi-person me-2"></i>
                            Register for Free
                        </Link>
                    </div>
                </div>
            </section>

            <section className='container-box2 py-5'>
                <div className="row h-100 py-md-5">
                    <div className="col-md-6 about-section">
                        <h5>About us</h5>
                        <div className="line"></div>

                        {/* <h2>We run all kinds of IT services that vow your success</h2> */}

                        <p className='mt-4 mb-md-0 mb-4 paragraph fs-5'>
                            At Job Fitmeter, we're dedicated to transforming the way companies navigate the complex landscape of talent acquisition. We believe that the key to successful hiring lies in the power of collective knowledge and the ability to make informed decisions. Our platform serves as the bridge that connects employers, enabling them to share valuable insights about their employees and, in turn, helping other companies make better-informed hiring decisions.
                        </p>
                    </div>
                    <div className="col-md-6 ps-md-5 about-section mt-md-0 mt-5">
                        <h5>Our Mission</h5>
                        <div className="line"></div>

                        {/* <h2>We run all kinds of IT services that vow your success</h2> */}

                        <p className='mt-4 mb-md-0 mb-4 paragraph fs-5'>
                            Our mission is simple yet profound: to facilitate the exchange of candid feedback between employers about their employees. This feedback, which is anonymized to ensure privacy and fairness, is a valuable resource for organizations seeking to build high-performing teams and nurture a culture of continuous improvement.
                        </p>
                    </div>
                </div>
            </section>

            <section className='container-box2 py-5 features-section'>
                <div className="row py-md-5 text-">
                    <div className="col-md-3 col-sm-6 text-white feature">
                        <img src={feature1} alt="" />
                        <h4>Unbiased Reviews</h4>
                        <p className='paragraph text-light mb-0'>
                            We believe in transparency and objectivity. Our platform ensures that all reviews are anonymized and honest, creating a level playing field for employers and candidates alike.
                        </p>
                    </div>
                    <div className="col-md-3 col-sm-6 text-white feature">
                        <img src={feature2} alt="" />
                        <h4>Informed Decisions</h4>
                        <p className='paragraph text-light mb-0 '>
                            Job Fitmeter empowers employers to make more informed hiring decisions, leading to stronger teams and a more productive workforce.
                        </p>
                    </div>
                    <div className="col-md-3 col-sm-6 text-white feature">
                        <img src={feature3} alt="" />
                        <h4>Continuous Improvement</h4>
                        <p className='paragraph text-light mb-0'>
                            Employees benefit from the constructive feedback shared on our platform, helping them identify areas for growth and development.</p>
                    </div>
                    <div className="col-md-3 col-sm-6 text-white feature">
                        <img src={feature4} alt="" />
                        <h4>Efficiency</h4>
                        <p className='paragraph text-light mb-0'>
                            Cut down on costly hiring mistakes by accessing a wealth of information about potential hires before extending an offer.
                        </p>
                    </div>
                </div>
            </section>

            <section className='container-box2 py-5 banner-bg'>
                <div className="row py-md-5 justify-content-md-start justify-content-center">

                    <div className="col-md-7 col-11 banner-card">
                        {/* <h5>SEARCH CANDIDATE</h5>
                         */}

                        <h3 className='text-dark-blue'>Unlocking Job Fitmeter's Insights </h3>
                        <div className="line"></div>

                        <h5 className='fw-bold text-dark-blue'>Register and Share:</h5>
                        <p className="paragraph">
                            Companies can easily register on our platform and share their candid reviews of current or former employees. These reviews cover a range of attributes and skills, providing a comprehensive understanding of an individual's strengths and areas for growth.
                        </p>

                        <h5 className='fw-bold text-dark-blue'>Access Valuable Insights:</h5>
                        <p className='paragraph mb-0'>
                            Other organizations looking to make hiring decisions can access these insights, gaining a deeper understanding of candidates beyond the traditional resume or interview process. By leveraging the collective wisdom of the business community, they can confidently choose candidates who best fit their unique needs and culture.
                        </p>
                    </div>
                </div>
            </section>



            <section className='container-box2 py-5'>
                <div className="row py-md-5">
                    <div className="col-md-6 pe-md-5 d-md-block d-none">
                        <img src="https://media.istockphoto.com/id/1226829497/photo/young-woman-doing-a-job-interview.webp?b=1&s=170667a&w=0&k=20&c=ywZSMuz9bCik2kEZQ_FswfL8K_6UXQ0szrurEX5H8Uo=" className='w-100' style={{ height: '800px', objectFit: 'cover' }} alt="" />
                    </div>
                    <div className="col-md-6">
                        <div className="about-section">
                            <h5>For Employees</h5>
                            <div className="line"></div>
                            <h2 className='mb-4'>Transparent Track Record</h2>

                            <div className='achieve-card'>
                                <img src={feature1} alt="" />
                                <div>
                                    <h5>Showcase Your Strengths with Confidence</h5>
                                    <p>
                                        Generate a review link on Job Fitmeter to confidently demonstrate your skills and abilities, endorsed by previous employers.
                                    </p>
                                </div>
                            </div>

                            <div className='achieve-card'>
                                <img src={feature2} alt="" />
                                <div>
                                    <h5>Demonstrate a Commitment to Transparency</h5>
                                    <p>
                                        Share review links to show your honesty and openness about your work history, building trust with potential employers.
                                    </p>
                                </div>
                            </div>

                            <div className='achieve-card mb-5'>
                                <img src={feature3} alt="" />
                                <div>
                                    <h5>Stand Out in the Hiring Process</h5>
                                    <p>
                                        Use review links to set yourself apart from the competition and provide concrete evidence of your qualifications and contributions to hiring managers.
                                    </p>
                                </div>
                            </div>

                            <div className='text-md-start text-center'>
                                <Link to="/generate-review-link" className='blue-button bg-dark-blue text-decoration-none me-4'>
                                    <i className="bi bi-link me-2"></i>
                                    Generate Review Link
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Home
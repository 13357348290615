import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const TermsAndConditions = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])

    return (
        <>
            <section className="page-header py-5">
                <div className="container-box py-4">
                    <div className="text-white text-center">
                        <h1 className="fw-bold display-4">Terms and Conditions</h1>
                        <p className="mt-4 header-path-link">
                            <Link to="/" className="text-blue text-decoration-none">
                                {" "}
                                Home
                            </Link>
                            <i className="bi bi-arrow-right mx-3"></i>
                            Terms and Conditions
                        </p>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container-box py-md-4">
                    <p className="paragraph fs-5">
                        Please read these Terms and Conditions carefully before using JobFitMeter ("the Platform"). By accessing or using the Platform, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use the Platform.
                    </p>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>User Responsibilities</h2>

                        <h5 className='fw-bold text-dark-blue'>Company Registration: </h5>
                        <p className="paragraph fs-5">
                            Only registered companies and employers are permitted to use the Platform. By registering, you represent and warrant that you are authorized to act on behalf of the registered company and that all information provided is accurate.
                        </p>

                        <h5 className='fw-bold text-dark-blue'>Review Content: </h5>
                        <p className="paragraph fs-5">
                            Employers and registered companies are responsible for the accuracy and fairness of the reviews and feedback they post. False, defamatory, or misleading content is prohibited.
                        </p>

                        <h5 className='fw-bold text-dark-blue'>Data Security: </h5>
                        <p className="paragraph fs-5">
                            Users are responsible for maintaining the security of their accounts, including login credentials, and should promptly notify JobFitMeter of any unauthorized access.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Candidate Information</h2>

                        <h5 className='fw-bold text-dark-blue'>Collection: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter collects and maintains minimal candidate information, including the candidate's name and Aadhar card number, to ensure platform uniqueness and accuracy.
                        </p>

                        <h5 className='fw-bold text-dark-blue'>Candidate Rights: </h5>
                        <p className="paragraph fs-5">
                            Candidates do not have the ability to update, edit, or remove their information. JobFitMeter does not collect or store any other personal or professional information about candidates.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Review Content</h2>

                        <h5 className='fw-bold text-dark-blue'>Responsibility: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter disclaims any responsibility for the reviews and ratings posted by registered companies. These reviews represent the opinions and experiences of the respective employers and do not reflect the views of JobFitMeter.
                        </p>

                        <h5 className='fw-bold text-dark-blue'>Review Moderation: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter reserves the right to moderate, remove, or block reviews that violate our terms and conditions or community guidelines.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Data Security</h2>

                        <h5 className='fw-bold text-dark-blue'>Protection: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter employs security measures to protect the information provided by users. However, users are encouraged to use best practices for online security and privacy.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Cookie Usage</h2>

                        <h5 className='fw-bold text-dark-blue'>Cookies: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter may use cookies and similar technologies for analytics and to enhance user experience. Users can manage their cookie preferences through browser settings.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Termination</h2>

                        <h5 className='fw-bold text-dark-blue'>Account Suspension: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter reserves the right to suspend or terminate user accounts that violate these terms and conditions.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Changes to Terms</h2>

                        <h5 className='fw-bold text-dark-blue'>Updates: </h5>
                        <p className="paragraph fs-5">
                            JobFitMeter may update these Terms and Conditions to reflect changes in practices or compliance with applicable laws. Users are responsible for reviewing these terms periodically for updates.
                        </p>
                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Contact Us</h2>
                        <p className="paragraph fs-5">
                            If you have questions or concerns about these Terms and Conditions, please contact us at
                            <a href='mailto:support@jobfitmeter.com' className='text-decoration-none text-blue fs-5'> support@jobfitmeter.com</a>
                        </p>

                    </div>

                    <div className='pt-4'>
                        <h2 className='head-2 mb-4'>Conclusion</h2>
                        <p className="paragraph fs-5">
                            JobFitMeter is dedicated to maintaining a transparent and secure platform for employers and registered companies to exchange professional feedback. By using our platform, you acknowledge and agree to these Terms and Conditions.
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TermsAndConditions